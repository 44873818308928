const { createAction, createReducer } = require("@reduxjs/toolkit");

const login = createAction("AUTH_LOGIN");
const logout = createAction("AUTH_LOGOUT");
const upic = createAction("AUTH_UPIC");
const changePageTitle = createAction("AUTH_PAGETITLE");
const changeSidebar = createAction("set");

const user = localStorage.getItem("user")
  ? JSON.parse(localStorage.getItem("user"))
  : null;
const userpic = localStorage.getItem("userpic")
  ? localStorage.getItem("userpic")
  : null;
const signedIn = user ? true : false;
const defaultState = {
  user: user,
  signedIn: signedIn,
  endPoint: "https://yio.pic-yasunaga.co.id/api/admin",
  userPic: userpic,
  sidebarShow: "responsive",
};

const auth = createReducer(defaultState, {
  [login]: (stat, action) => {
    localStorage.setItem("user", JSON.stringify(action.user));
    return {
      ...stat,
      user: action.user,
      signedIn: true,
    };
  },
  [upic]: (stat, action) => {
    localStorage.setItem("userpic", action.pic);
    return {
      ...stat,
      userPic: action.pic,
      signedIn: true,
    };
  },
  [logout]: (stat) => {
    localStorage.removeItem("user");
    localStorage.removeItem("userpic");
    return {
      ...stat,
      user: null,
      signedIn: false,
      userPic: null,
    };
  },
  [changePageTitle]: (stat, action) => {
    return {
      ...stat,
      pageTitle: action.title,
    };
  },
  [changeSidebar]: (stat, action) => {
    return { ...stat, sidebarShow: action.sidebarShow };
  },
});
export default auth;
